import HackleCore from "../../../../core/HackleCore"
import { InAppMessagePresentationContext } from "../../presentation/InAppMessagePresenter"
import { InAppMessageActionEvent, InAppMessageEvent, InAppMessageImageImpressionEvent } from "./InAppMessageEvent"
import { HackleEvent } from "../../../../core/internal/model/model"

export class InAppMessageEventTracker {
  constructor(private readonly core: HackleCore) {}

  track(context: InAppMessagePresentationContext, event: InAppMessageEvent, timestamp: number): void {
    const hackleEvent = this.createTrackEvent(event, context)
    this.core.track(hackleEvent, context.user, timestamp)
  }

  private createTrackEvent(event: InAppMessageEvent, context: InAppMessagePresentationContext): HackleEvent {
    switch (event.type) {
      case "IMPRESSION":
        return {
          key: "$in_app_impression",
          properties: {
            ...context.properties,
            in_app_message_id: context.inAppMessage.id,
            in_app_message_key: context.inAppMessage.key,
            in_app_message_display_type: context.message.layout.displayType,
            title_text: context.message.text?.title?.text,
            body_text: context.message.text?.body?.text,
            button_text: context.message.buttons.map((it) => it.text),
            image_url: context.message.images.map((it) => it.imagePath)
          }
        }
      case "CLOSE":
        return {
          key: "$in_app_close",
          properties: {
            ...context.properties,
            in_app_message_id: context.inAppMessage.id,
            in_app_message_key: context.inAppMessage.key,
            in_app_message_display_type: context.message.layout.displayType
          }
        }
      case "ACTION":
        const actionEvent = event as InAppMessageActionEvent
        return {
          key: "$in_app_action",
          properties: {
            ...context.properties,
            in_app_message_id: context.inAppMessage.id,
            in_app_message_key: context.inAppMessage.key,
            in_app_message_display_type: context.message.layout.displayType,
            action_type: actionEvent.action.type,
            action_area: actionEvent.area,
            action_value: actionEvent.action.value,
            button_text: actionEvent.buttonText,
            image_url: actionEvent.imageUrl,
            image_order: actionEvent.imageOrder
          }
        }
      case "IMAGE_IMPRESSION":
        const imageImpressionEvent = event as InAppMessageImageImpressionEvent
        return {
          key: "$in_app_image_impression",
          properties: {
            ...context.properties,
            in_app_message_id: context.inAppMessage.id,
            in_app_message_key: context.inAppMessage.key,
            in_app_message_display_type: context.message.layout.displayType,
            image_url: imageImpressionEvent.imageUrl,
            image_order: imageImpressionEvent.imageOrder
          }
        }
    }
  }
}
