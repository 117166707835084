import { UserTargetEvent } from "../model/model"
import { UserTargetsResponseDto } from "./dto"

export class UserTargets {
  constructor(private readonly events: UserTargetEvent[]) {}

  static empty(): UserTargets {
    return this.from([])
  }

  static from(events: UserTargetEvent[]): UserTargets {
    return new UserTargets(events)
  }

  static fromDto(dto: UserTargetsResponseDto): UserTargets {
    return this.from(dto.events.map((event) => UserTargetEvent.fromJson(event)))
  }

  get rawEvents(): UserTargetEvent[] {
    return this.events
  }
}
