import TargetMatcher from "../match/TargetMatcher"
import { ConditionMatcherFactory } from "../match/ConditionMatcher"
import ActionResolver from "../action/ActionResolver"
import Bucketer from "../bucket/Bucketer"
import EvaluationFlow from "./EvaluationFlow"
import {
  CompletedEvaluator,
  ContainerEvaluator,
  DefaultRuleEvaluator,
  DraftEvaluator,
  ExperimentFlow,
  ExperimentTargetEvaluator,
  IdentifierEvaluator,
  OverrideEvaluator,
  PausedEvaluator,
  TargetRuleEvaluator,
  TrafficAllocateEvaluator
} from "../evalautor/experiment/ExperimentFlowEvaluator"
import ExperimentTargetDeterminer from "../target/ExperimentTargetDeterminer"
import TargetRuleDeterminer from "../target/TargetRuleDeterminer"
import { ExperimentType } from "../../model/model"
import OverrideResolver from "../target/OverrideResolver"
import ContainerResolver from "../container/ContainerResolver"
import { ManualOverrideStorage } from "../target/ManualOverrideStorage"
import Evaluator from "../evalautor/Evaluator"
import {
  DraftInAppMessageFlowEvaluator,
  HiddenInAppMessageFlowEvaluator,
  InAppMessageFlow,
  OverrideInAppMessageFlowEvaluator,
  PauseInAppMessageFlowEvaluator,
  PeriodInAppMessageFlowEvaluator,
  PlatformInAppMessageFlowEvaluator,
  TargetInAppMessageFlowEvaluator
} from "../evalautor/iam/InAppMessageFlowEvaluator"
import {
  HiddenInAppMessageMatcher,
  InAppMessageResolver,
  TargetInAppMessageMatcher,
  UserOverrideInAppMessageMatcher
} from "../target/InAppMessageResolver"
import InAppMessageHiddenStorage from "../target/InAppMessageHiddenStorage"
import RemoteConfigParameterTargetRuleDeterminer from "../target/RemoteConfigParameterTargetRuleDeterminer"
import { HackleCoreContext } from "../../HackleCoreContext"
import { Clock } from "../../util/TimeUtil"

export default class EvaluationFlowFactory {
  private readonly abTestFlow: ExperimentFlow
  private readonly featureFlagFlow: ExperimentFlow
  private readonly inAppMessageFlow: InAppMessageFlow
  readonly remoteConfigParameterTargetRuleDeterminer: RemoteConfigParameterTargetRuleDeterminer

  constructor(
    evaluator: Evaluator,
    manualOverrideStorage: ManualOverrideStorage,
    inAppMessageHiddenStorage: InAppMessageHiddenStorage,
    clock: Clock
  ) {
    const bucketer = new Bucketer()
    const targetMatcher = HackleCoreContext.register(
      "targetMatcher",
      new TargetMatcher(new ConditionMatcherFactory(evaluator, clock))
    )
    const actionResolver = new ActionResolver(bucketer)
    const overrideResolver = new OverrideResolver(manualOverrideStorage, targetMatcher, actionResolver)
    const containerResolver = new ContainerResolver(bucketer)

    this.abTestFlow = EvaluationFlow.of(
      new OverrideEvaluator(overrideResolver),
      new IdentifierEvaluator(),
      new ContainerEvaluator(containerResolver),
      new ExperimentTargetEvaluator(new ExperimentTargetDeterminer(targetMatcher)),
      new DraftEvaluator(),
      new PausedEvaluator(),
      new CompletedEvaluator(),
      new TrafficAllocateEvaluator(actionResolver)
    )

    this.featureFlagFlow = EvaluationFlow.of(
      new DraftEvaluator(),
      new PausedEvaluator(),
      new CompletedEvaluator(),
      new OverrideEvaluator(overrideResolver),
      new IdentifierEvaluator(),
      new TargetRuleEvaluator(new TargetRuleDeterminer(targetMatcher), actionResolver),
      new DefaultRuleEvaluator(actionResolver)
    )

    const inAppMessageResolver = new InAppMessageResolver(evaluator)
    this.inAppMessageFlow = EvaluationFlow.of(
      new PlatformInAppMessageFlowEvaluator(),
      new OverrideInAppMessageFlowEvaluator(new UserOverrideInAppMessageMatcher(), inAppMessageResolver),
      new DraftInAppMessageFlowEvaluator(),
      new PauseInAppMessageFlowEvaluator(),
      new PeriodInAppMessageFlowEvaluator(),
      new HiddenInAppMessageFlowEvaluator(new HiddenInAppMessageMatcher(inAppMessageHiddenStorage)),
      new TargetInAppMessageFlowEvaluator(new TargetInAppMessageMatcher(targetMatcher), inAppMessageResolver)
    )

    this.remoteConfigParameterTargetRuleDeterminer = new RemoteConfigParameterTargetRuleDeterminer(
      targetMatcher,
      bucketer
    )
  }

  getExperimentFlow(experimentType: ExperimentType): ExperimentFlow {
    switch (experimentType) {
      case "AB_TEST":
        return this.abTestFlow
      case "FEATURE_FLAG":
        return this.featureFlagFlow
    }
  }

  getInAppMessageFlow(): InAppMessageFlow {
    return this.inAppMessageFlow
  }
}
