export default class TypeUtil {
  static asString(value: any): string | undefined {
    switch (typeof value) {
      case "string":
        return value
      case "number":
        return value.toString()
      case "boolean":
        return value ? "true" : "false"
      default:
        return undefined
    }
  }

  static asNumber(value: any): number | undefined {
    switch (typeof value) {
      case "number":
        return this.asActualNumber(value)
      case "string":
        return this.asActualNumber(Number(value))
      default:
        return undefined
    }
  }

  static asBoolean(value: any): boolean | undefined {
    switch (typeof value) {
      case "boolean":
        return value
      case "string":
        switch (value) {
          case "true":
            return true
          case "false":
            return false
          default:
            return undefined
        }
      default:
        return undefined
    }
  }

  private static asActualNumber(number: number): number | undefined {
    if (!isNaN(number) && isFinite(number)) {
      return number
    } else {
      return undefined
    }
  }
}
