import { InAppMessageView } from "../view/InAppMessageView"
import {
  InAppMessageActionInteraction,
  InAppMessageCloseInteraction,
  InAppMessageImageImpressionInteraction,
  InAppMessageInteraction
} from "./InAppMessageInteraction"
import { InAppMessageEventHandler } from "./InAppMessageEventHandler"
import { InAppMessageAction } from "../../../../core/internal/model/model"
import { InAppMessageActionEvent, InAppMessageImageImpressionEvent } from "./InAppMessageEvent"

export interface InAppMessageInteractionHandler<Interaction extends InAppMessageInteraction> {
  supports(interaction: InAppMessageInteraction): boolean

  handle(view: InAppMessageView, interaction: Interaction): void
}

export class InAppMessageInteractionHandlerFactory {
  constructor(private readonly handlers: InAppMessageInteractionHandler<InAppMessageInteraction>[]) {}

  get(interaction: InAppMessageInteraction): InAppMessageInteractionHandler<InAppMessageInteraction> | undefined {
    return this.handlers.find((it) => it.supports(interaction))
  }
}

export class InAppMessageCloseInteractionHandler
  implements InAppMessageInteractionHandler<InAppMessageCloseInteraction>
{
  handle(view: InAppMessageView, interaction: InAppMessageCloseInteraction): void {
    view.close()
  }

  supports(interaction: InAppMessageInteraction): boolean {
    return interaction.type === "CLOSE"
  }
}

export class InAppMessageActionInteractionHandler
  implements InAppMessageInteractionHandler<InAppMessageActionInteraction>
{
  constructor(private readonly eventHandler: InAppMessageEventHandler) {}

  handle(view: InAppMessageView, interaction: InAppMessageActionInteraction): void {
    const event = this.eventOrNull(interaction)
    if (!event) return
    this.eventHandler.handle(view, event)
  }

  private eventOrNull(interaction: InAppMessageActionInteraction): InAppMessageActionEvent | null {
    const action = InAppMessageAction.fromJson(interaction.action)
    if (!action) return null
    return {
      type: "ACTION",
      action: action,
      area: interaction.area,
      buttonText: interaction.buttonText,
      imageUrl: interaction.imageUrl,
      imageOrder: interaction.imageOrder
    }
  }

  supports(interaction: InAppMessageInteraction): boolean {
    return interaction.type === "ACTION"
  }
}

export class InAppMessageImageImpressionInteractionHandler
  implements InAppMessageInteractionHandler<InAppMessageImageImpressionInteraction>
{
  constructor(private readonly eventHandler: InAppMessageEventHandler) {}

  handle(view: InAppMessageView, interaction: InAppMessageImageImpressionInteraction): void {
    const event: InAppMessageImageImpressionEvent = {
      type: "IMAGE_IMPRESSION",
      imageUrl: interaction.imageUrl,
      imageOrder: interaction.imageOrder
    }
    this.eventHandler.handle(view, event)
  }

  supports(interaction: InAppMessageInteraction): boolean {
    return interaction.type === "IMAGE_IMPRESSION"
  }
}
