import { v4 } from "uuid"
import { InAppMessageLifecycle, InAppMessageLifecycleEvent } from "./InAppMessageLifecycle"

type InAppMessageLifecycleRegistryKey = `${InAppMessageLifecycleEvent["type"]}_${InAppMessageLifecycleEvent["id"]}`
export type InAppMessageLifecycleRegistry = Record<InAppMessageLifecycleRegistryKey, () => void>

export class HackleInAppMessageLifecycleRegistry {
  private record: InAppMessageLifecycleRegistry = {}

  private resolveKey(type: InAppMessageLifecycle, id: string): InAppMessageLifecycleRegistryKey {
    return `${type}_${id}`
  }

  register(type: InAppMessageLifecycle, handler: () => void) {
    const id = v4()

    const key = this.resolveKey(type, id)
    this.record[key] = handler

    return id
  }

  getAndRemove(
    type: InAppMessageLifecycle,
    id: string
  ): InAppMessageLifecycleRegistry[InAppMessageLifecycleRegistryKey] | undefined {
    const key = this.resolveKey(type, id)
    const { [key]: handler, ...rest } = this.record

    this.record = rest
    return handler
  }
}
