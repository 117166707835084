import { BROWSER_BATCH_SIZE } from "../../../../config"
import Logger from "../../logger"
import { Decision, FeatureFlagDecision, InAppMessageDecision, RemoteConfigDecision } from "../../model/model"
import CollectionUtil from "../../util/CollectionUtil"
import ObjectUtil from "../../util/ObjectUtil"
import { isCounterMetric } from "../Counter"
import { FlushMetricRegistry } from "../flush/FlushMetricRegistry"
import { Metric } from "../Metric"
import { Metrics } from "../Metrics"
import { isTimerMetric, TimerSample } from "../Timer"
import { Transport, TransportRequest, TransportResponse } from "../../transport/Transport"
import { Scheduler } from "../../scheduler/Scheduler"
import { Lifecycle } from "../../lifecycle/Lifecycle"
import { LifecycleChangeListener } from "../../lifecycle/LifecycleChangeListener"

export class MonitoringMetricRegistry extends FlushMetricRegistry implements LifecycleChangeListener {
  constructor(
    private readonly url: string,
    private readonly transport: Transport,
    private readonly synchronousTransport: Transport,
    scheduler: Scheduler,
    pushIntervalMillis: number
  ) {
    super(scheduler, pushIntervalMillis)
    this.start()
  }

  protected flushMetrics(metrics: Metric[], sync: boolean): void {
    CollectionUtil.chunked(metrics.filter(this.isDispatchTarget.bind(this)), BROWSER_BATCH_SIZE).forEach(
      (targetMetrics) => {
        this.dispatch(targetMetrics, sync)
      }
    )
  }

  // Dispatch only measured metrics
  private isDispatchTarget(metric: Metric): boolean {
    if (isCounterMetric(metric) || isTimerMetric(metric)) return metric.count() > 0

    return false
  }

  private dispatch(metrics: Metric[], sync: boolean) {
    const request = this.createRequest(metrics)
    this.execute(request, sync).catch((e) => Logger.log.debug(`Failed to flushing metrics: ${e}`))
  }

  private createRequest(metrics: Metric[]): TransportRequest {
    return TransportRequest.builder()
      .url(this.url)
      .method("POST")
      .body(JSON.stringify(this.batch(metrics)))
      .addHeader("Content-Type", "application/json")
      .build()
  }

  private execute(request: TransportRequest, sync: boolean): Promise<TransportResponse> {
    if (sync) {
      return this.synchronousTransport.send(request)
    } else {
      return this.transport.send(request)
    }
  }

  private batch(metrics: Metric[]) {
    return {
      metrics: metrics.map((metric) => ({
        name: metric.id.name,
        type: metric.id.type,
        tags: metric.id.tags,
        measurements: ObjectUtil.fromMap(
          CollectionUtil.associate(metric.measure(), (measurement) => [measurement.field, measurement.value])
        )
      }))
    }
  }

  onLifecycleChanged(visibility: Lifecycle, _: number): void {
    switch (visibility) {
      case "hidden":
      case "pagehide":
        return this.publish(true)
      case "visible":
      case "pageshow":
      case "blur":
      case "locationChange":
      case "focus":
        return
    }
  }
}

export class DecisionMetrics {
  static experiment(sample: TimerSample, key: number, decision: Decision) {
    const timer = Metrics.timer("experiment.decision", {
      key: String(key),
      variation: decision.variation,
      reason: String(decision.reason)
    })
    sample.stop(timer)
  }

  static featureFlag(sample: TimerSample, key: number, decision: FeatureFlagDecision) {
    const timer = Metrics.timer("feature.flag.decision", {
      key: String(key),
      on: String(decision.isOn),
      reason: String(decision.reason)
    })
    sample.stop(timer)
  }

  static remoteConfig(sample: TimerSample, key: string, decision: RemoteConfigDecision) {
    const timer = Metrics.timer("remote.config.decision", {
      key: String(key),
      reason: String(decision.reason)
    })
    sample.stop(timer)
  }

  static inAppMessage(sample: TimerSample, key: number, decision: InAppMessageDecision) {
    const timer = Metrics.timer("iam.decision", {
      key: String(key),
      show: String(decision.isShow()),
      reason: String(decision.reason)
    })
    sample.stop(timer)
  }
}

export type ApiOperation = "get.workspace" | "post.events" | "get.cohorts" | "get.user-targets"

export class ApiCallMetrics {
  static record(operation: ApiOperation, sample: TimerSample, response: TransportResponse | null) {
    const timer = Metrics.timer("api.call", {
      operation,
      success: ApiCallMetrics.success(response)
    })
    sample.stop(timer)
  }

  private static success(response: TransportResponse | null): string {
    if (response === null) {
      return "false"
    }
    return String(response.isSuccessful() || response.isNotModified())
  }
}
