export class Urls {
  static serverFetch(sdkUrl: string, sdkKey: string): string {
    return `${sdkUrl}/api/v2/workspaces/${sdkKey}/config`
  }

  static clientFetch(sdkUrl: string, sdkKey: string): string {
    return `${sdkUrl}/api/v2/workspaces/${sdkKey}/config`
  }

  static userCohorts(sdkUrl: string): string {
    return `${sdkUrl}/api/v1/cohorts`
  }

  static serverDispatch(eventUrl: string): string {
    return `${eventUrl}/api/v2/events`
  }

  static clientDispatch(eventUrl: string): string {
    return `${eventUrl}/api/v2/w/events`
  }

  static beaconDispatch(eventUrl: string, sdkKey: string): string {
    return `${eventUrl}/api/v2/w/${sdkKey}/events`
  }

  static inAppMessageRenderer(cdnUrl: string, version: string): string {
    return `${cdnUrl}/sdk/iam-renderer/${version}/iam-renderer.js`
  }

  static monitoring(monitoringUrl: string): string {
    return `${monitoringUrl}/metrics`
  }

  static userTargets(sdkUrl: string): string {
    return `${sdkUrl}/api/v1/user-targets`
  }
}
