import ConditionMatcher from "./ConditionMatcher"
import { EvaluatorContext, EvaluatorRequest } from "../evalautor/Evaluator"
import { TargetCondition } from "../../model/model"
import ValueOperatorMatcher from "./ValueOperatorMatcher"

export class CohortConditionMatcher implements ConditionMatcher {
  constructor(private readonly valueOperatorMatcher: ValueOperatorMatcher) {}

  matches(request: EvaluatorRequest, context: EvaluatorContext, condition: TargetCondition): boolean {
    if (condition.key.type !== "COHORT") {
      throw new Error(`Unsupported TargetKeyType [${condition.key.type}]`)
    }
    const cohortIds = request.user.cohorts?.map((it) => it.id) ?? []
    return this.valueOperatorMatcher.matches(cohortIds, condition.match)
  }
}
