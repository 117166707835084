import ConditionMatcher from "./ConditionMatcher"
import { EvaluatorContext, EvaluatorRequest } from "../evalautor/Evaluator"
import {
  NumberOfEventsInDaysExpression,
  NumberOfEventsWithPropertyInDaysExpression,
  TargetCondition,
  TargetSegmentationExpression,
  UserTargetEvent,
  UserTargetEventSupportedTargetKey
} from "../../model/model"
import ValueOperatorMatcher from "./ValueOperatorMatcher"
import ObjectUtil from "../../util/ObjectUtil"
import { Clock, SystemClock, TimeUtil } from "../../util/TimeUtil"
import CollectionUtil from "../../util/CollectionUtil"

export class UserTargetConditionMatcher implements ConditionMatcher {
  constructor(
    private readonly valueOperatorMatcher: ValueOperatorMatcher,
    private readonly clock: Clock
  ) {}

  private supports(targetKeyType: TargetCondition["key"]["type"]): targetKeyType is UserTargetEventSupportedTargetKey {
    return "NUMBER_OF_EVENTS_IN_DAYS" === targetKeyType || "NUMBER_OF_EVENTS_WITH_PROPERTY_IN_DAYS" === targetKeyType
  }

  matches(request: EvaluatorRequest, context: EvaluatorContext, condition: TargetCondition): boolean {
    const keyType = condition.key.type

    if (!this.supports(keyType)) {
      throw new Error(`Unsupported TargetKeyType [${keyType}]`)
    }

    const targetEvents = request.user.targetEvents ?? []
    switch (keyType) {
      case "NUMBER_OF_EVENTS_IN_DAYS":
        return new NumberOfEventsInDaysMatcher(this.valueOperatorMatcher, this.clock).matches(
          request,
          targetEvents,
          condition
        )
      case "NUMBER_OF_EVENTS_WITH_PROPERTY_IN_DAYS":
        return new NumberOfEventsWithPropertyInDaysMatcher(this.valueOperatorMatcher, this.clock).matches(
          request,
          targetEvents,
          condition
        )
      default:
        return keyType as never
    }
  }
}

abstract class TargetSegmentationExpressionMatcher<T extends TargetSegmentationExpression> {
  abstract valueOperatorMatcher: ValueOperatorMatcher

  // EvaluatorRequest에 timestamp 추가 필요 -> matches 호출 시점의 timestamp를 사용하기 위해, request 파라미터를 유지하였음.
  abstract matches(request: EvaluatorRequest, targetEvents: UserTargetEvent[], condition: TargetCondition): boolean

  abstract convertTargetConditionToExpression(condition: TargetCondition): T
}

export class NumberOfEventsInDaysMatcher extends TargetSegmentationExpressionMatcher<NumberOfEventsInDaysExpression> {
  constructor(
    readonly valueOperatorMatcher: ValueOperatorMatcher,
    private readonly clock: Clock
  ) {
    super()
  }

  matches(_request: EvaluatorRequest, targetEvents: UserTargetEvent[], condition: TargetCondition): boolean {
    const expression = this.convertTargetConditionToExpression(condition)
    const daysAgoUtc = this.clock.currentMillis() - TimeUtil.daysToUnit(expression.days, "milliseconds")
    const userTargetEvents = targetEvents.filter((it) => this.expressionMatch(it, expression))
    const sumOfEventCount = CollectionUtil.sum(userTargetEvents, (it) => it.countWithinDays(daysAgoUtc))

    return this.valueOperatorMatcher.matches(sumOfEventCount, condition.match)
  }

  private expressionMatch(targetEvent: UserTargetEvent, expression: NumberOfEventsInDaysExpression): boolean {
    return targetEvent.eventKey === expression.eventKey && targetEvent.property === null
  }

  convertTargetConditionToExpression(condition: TargetCondition): NumberOfEventsInDaysExpression {
    try {
      return JSON.parse(condition.key.name)
    } catch (error) {
      throw new Error(`Failed to parse UserTargetCondition. ${error}`)
    }
  }
}

export class NumberOfEventsWithPropertyInDaysMatcher extends TargetSegmentationExpressionMatcher<NumberOfEventsWithPropertyInDaysExpression> {
  constructor(
    readonly valueOperatorMatcher: ValueOperatorMatcher,
    private readonly clock: Clock
  ) {
    super()
  }

  matches(_request: EvaluatorRequest, targetEvents: UserTargetEvent[], condition: TargetCondition): boolean {
    const expression = this.convertTargetConditionToExpression(condition)
    const daysAgoUtc = this.clock.currentMillis() - TimeUtil.daysToUnit(expression.days, "milliseconds")
    const userTargetEvents = targetEvents.filter((it) => this.expressionMatch(it, expression))
    const sumOfEventCount = CollectionUtil.sum(userTargetEvents, (it) => it.countWithinDays(daysAgoUtc))

    return this.valueOperatorMatcher.matches(sumOfEventCount, condition.match)
  }

  private propertyMatch(property: UserTargetEvent["property"], propertyCondition: TargetCondition): boolean {
    if (property?.type !== propertyCondition.key.type || property.key !== propertyCondition.key.name) return false

    return this.valueOperatorMatcher.matches(property.value, propertyCondition.match)
  }

  private expressionMatch(
    targetEvent: UserTargetEvent,
    expression: NumberOfEventsWithPropertyInDaysExpression
  ): boolean {
    if (targetEvent.eventKey !== expression.eventKey) return false

    return this.propertyMatch(targetEvent.property, expression.propertyFilter)
  }

  convertTargetConditionToExpression(condition: TargetCondition): NumberOfEventsWithPropertyInDaysExpression {
    try {
      return JSON.parse(condition.key.name)
    } catch (error) {
      throw new Error(`Failed to parse UserTargetCondition. ${error}`)
    }
  }
}
