export type InAppMessageLifecycle = "OPEN" | "CLOSE"

export interface InAppMessageLifecycleEvent {
  type: InAppMessageLifecycle
  id: string
}

export const isInAppMessageLifecycleEvent = (e: CustomEvent): e is CustomEvent<InAppMessageLifecycleEvent> => {
  return typeof e.detail.id === "string" && ["OPEN", "CLOSE"].includes(e.detail.type)
}
