import { resolveIdentifiers, User, UserTargetEvent } from "../model/model"
import { Transport, TransportRequest, TransportResponse } from "../transport/Transport"
import { UserTargetsResponseDto } from "./dto"
import { Base64 } from "js-base64"
import { TimerSample } from "../metrics/Timer"
import { ApiCallMetrics } from "../metrics/monitoring/MonitoringMetricRegistry"
import { UserTargets } from "./UserTarget"

export interface UserTargetFetcher {
  fetch(user: User): Promise<UserTargets>
}

export class DefaultUserTargetFetcher implements UserTargetFetcher {
  constructor(
    private readonly url: string,
    private readonly transport: Transport
  ) {}

  async fetch(user: User): Promise<UserTargets> {
    const request = this.createRequest(user)
    const response = await this.execute(request)
    return this.handleResponse(response)
  }

  private createRequest(user: User): TransportRequest {
    const identifiers = { identifiers: resolveIdentifiers(user) }
    const body = Base64.encodeURL(JSON.stringify(identifiers))
    return TransportRequest.builder().url(this.url).method("GET").addHeader("X-HACKLE-USER", body).build()
  }

  private async execute(request: TransportRequest): Promise<TransportResponse> {
    const sample = TimerSample.start()
    try {
      const response = await this.transport.send(request)
      ApiCallMetrics.record("get.user-targets", sample, response)
      return response
    } catch (e) {
      ApiCallMetrics.record("get.user-targets", sample, null)
      throw e
    }
  }

  private handleResponse(response: TransportResponse): UserTargets {
    if (!response.isSuccessful()) {
      throw new Error(`Http status code: ${response.statusCode}`)
    }

    if (response.body === null) {
      throw new Error("Response body is null")
    }

    const dto = JSON.parse(response.body) as UserTargetsResponseDto
    return UserTargets.fromDto(dto)
  }
}
