import { InAppMessageRenderer } from "../script/InAppMessageRenderer"
import { InAppMessageUi } from "../InAppMessageUi"
import { InAppMessageRenderScriptLoader } from "../script/InAppMessageRenderScriptLoader"
import { InAppMessageView } from "./InAppMessageView"
import { InAppMessagePresentationContext } from "../../presentation/InAppMessagePresenter"
import { InAppMessageEvent } from "../event/InAppMessageEvent"
import { InAppMessageInteraction } from "../event/InAppMessageInteraction"
import InAppMessageRenderTypeResolver from "../internal/InAppMessageRenderTypeResolver"
import Logger from "../../../../core/internal/logger"
import { InAppMessageViewStatus } from "./InAppMessageViewStatus"
import { InAppMessageLifecycle, isInAppMessageLifecycleEvent } from "./InAppMessageLifecycle"
import { HackleInAppMessageLifecycleRegistry } from "./InAppMessageLifecycleRegistry"

export class ScriptInAppMessageView implements InAppMessageView {
  private static HACKLE_IAM_INTERACTION_EVENT_TYPE = "hackle-iam-interaction"
  private static HACKLE_IAM_LIFECYCLE_START_EVENT_TYPE = "hackle-iam-lifecycle-start"
  private static HACKLE_IAM_LIFECYCLE_END_EVENT_TYPE = "hackle-iam-lifecycle-end"
  private static CONTAINER_CLASS_NAME = "hackle-iam"
  private static LIFE_CYCLE_EVENT_LISTEN_TIMEOUT = 5000

  private container: HTMLElement | null = null
  private renderer: InAppMessageRenderer | null = null

  constructor(
    private readonly scriptLoader: InAppMessageRenderScriptLoader,
    public readonly context: InAppMessagePresentationContext,
    public readonly ui: InAppMessageUi,
    private readonly renderTypeResolver: InAppMessageRenderTypeResolver,
    public readonly status: InAppMessageViewStatus,
    private readonly lifecycleRegistry: HackleInAppMessageLifecycleRegistry
  ) {}

  private resolveLifecycleEnd = (e: unknown) => {
    if (!(e instanceof CustomEvent) || !isInAppMessageLifecycleEvent(e)) return
    const { id, type } = e.detail

    const handler = this.lifecycleRegistry.getAndRemove(type, id)
    handler?.()
  }

  async open() {
    if (!this.status.canOpen()) {
      Logger.log.debug(`InAppMessage is already opened. (key=${this.context.inAppMessage.key})`)
      return
    }

    try {
      this.ui.inAppMessageListener?.beforeInAppMessageOpen?.(this.context.inAppMessage)
      this.status.current = "OPENING"

      // add in-app message interaction listener
      window.addEventListener(
        ScriptInAppMessageView.HACKLE_IAM_INTERACTION_EVENT_TYPE,
        this.handleInAppMessageInteraction
      )
      window.addEventListener(ScriptInAppMessageView.HACKLE_IAM_LIFECYCLE_END_EVENT_TYPE, this.resolveLifecycleEnd)

      // load script
      await this.scriptLoader.load()

      // add view container
      const container = this.addContainer()
      container.style.display = "none"
      container.classList.add(`${ScriptInAppMessageView.CONTAINER_CLASS_NAME}--loading`)

      // instantiate renderer
      const script = (window as any).HackleInAppMessageRenderer
      this.renderer = new script.HackleInAppMessageRenderer(container) as InAppMessageRenderer

      const renderType = this.renderTypeResolver.resolve(this.context)

      // render
      await this.renderer.render({
        messageId: this.context.inAppMessage.id,
        message: this.context.message.toJson(),
        renderType
      })
      container.classList.remove(`${ScriptInAppMessageView.CONTAINER_CLASS_NAME}--loading`)
      container.style.display = "block"

      this.handleInAppMessageLifecycle(
        "OPEN",
        () => {
          this.handleEvent({ type: "IMPRESSION" })

          this.status.current = "OPENED"
          this.ui.inAppMessageListener?.afterInAppMessageOpen?.(this.context.inAppMessage)
        },
        () => {
          Logger.log.warn(`Failed to open inAppMessage with [Timeout expired.]`)
          this.destroy()
        }
      )
    } catch (e) {
      this.destroy()
    }
  }

  close() {
    // if view already closed, do nothing
    if (!this.status.canClose()) {
      Logger.log.debug(`InAppMessage is already closed. (key=${this.context.inAppMessage.key})`)
      return
    }

    this.ui.inAppMessageListener?.beforeInAppMessageClose?.(this.context.inAppMessage)
    this.status.current = "CLOSING"
    this.handleInAppMessageLifecycle(
      "CLOSE",
      () => {
        this.handleEvent({ type: "CLOSE" })
        this.destroy()
        this.ui.inAppMessageListener?.afterInAppMessageClose?.(this.context.inAppMessage)
      },
      () => {
        Logger.log.warn(`Failed to close inAppMessage with [Timeout expired.]`)
        this.destroy()
        this.ui.inAppMessageListener?.afterInAppMessageClose?.(this.context.inAppMessage)
      }
    )
  }

  private handleInAppMessageLifecycle(type: InAppMessageLifecycle, onSuccess: () => void, onError: () => void) {
    const id = this.lifecycleRegistry.register(type, onSuccess)

    window.dispatchEvent(
      new CustomEvent(ScriptInAppMessageView.HACKLE_IAM_LIFECYCLE_START_EVENT_TYPE, {
        detail: { type, id }
      })
    )

    setTimeout(() => {
      const handler = this.lifecycleRegistry.getAndRemove(type, id)
      const alreadyHandled = handler === undefined

      if (alreadyHandled) return

      onError()
    }, ScriptInAppMessageView.LIFE_CYCLE_EVENT_LISTEN_TIMEOUT)
  }

  destroy() {
    window.removeEventListener(
      ScriptInAppMessageView.HACKLE_IAM_INTERACTION_EVENT_TYPE,
      this.handleInAppMessageInteraction
    )
    window.removeEventListener(ScriptInAppMessageView.HACKLE_IAM_LIFECYCLE_END_EVENT_TYPE, this.resolveLifecycleEnd)
    this.renderer?.destroy()
    this.renderer = null
    this.removeContainer()
    this.ui.currentMessageView = null
    this.status.current = "CLOSED"
  }

  private addContainer() {
    if (!this.container) {
      const container = document.createElement("div")
      container.classList.add(ScriptInAppMessageView.CONTAINER_CLASS_NAME)
      this.container = container
    }

    if ((process.env.NODE_ENV as string | undefined) === "test") {
      this.container.setAttribute("data-testid", ScriptInAppMessageView.CONTAINER_CLASS_NAME)
    }

    if (!this.container.parentNode) {
      document.body.appendChild(this.container)
    }

    return this.container
  }

  private removeContainer() {
    if (this.container) {
      this.container.parentNode?.removeChild(this.container)
      this.container = null
    }
  }

  private handleInAppMessageInteraction = (e: Event) => {
    if (e instanceof CustomEvent && e.type === ScriptInAppMessageView.HACKLE_IAM_INTERACTION_EVENT_TYPE) {
      const interaction: InAppMessageInteraction = e.detail
      this.handleInteraction(interaction)
    }
  }

  private handleInteraction(interaction: InAppMessageInteraction) {
    const interactionHandler = this.ui.interactionHandlerFactory.get(interaction)
    if (!interactionHandler) return
    interactionHandler.handle(this, interaction)
  }

  private handleEvent(event: InAppMessageEvent) {
    this.ui.eventHandler.handle(this, event)
  }
}
