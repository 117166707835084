import {
  HackleUser,
  IdentifiersBuilder,
  IdentifierType,
  Properties,
  resolveIdentifiers,
  User
} from "../../../core/internal/model/model"
import { IStorage } from "../../../core/internal/storage/Storage"
import { UserListener } from "../../../core/internal/user/UserListener"
import { isSameUser, mergeUsers, UserManager } from "../../../core/internal/user/UserManager"
import ObjectUtil from "../../../core/internal/util/ObjectUtil"
import { DEVICE_ID_STORAGE_KEY, USER_ID_STORAGE_KEY } from "../../../config"
import { PropertyOperations } from "../../property/PropertyOperations"
import { UserCohorts } from "../../../core/internal/user/UserCohort"
import { UserCohortFetcher } from "../../../core/internal/user/UserCohortFetcher"
import { UserTargetFetcher } from "../../../core/internal/user/UserTargetFetcher"
import HacklePropertyGenerator from "../../property/HacklePropertyGenerator"
import Logger from "../../../core/internal/logger"
import { Clock } from "../../../core/internal/util/TimeUtil"
import { CampaignParser } from "../../attribution/CampaignParser"
import { PageManager } from "../../../core/internal/page/PageManager"
import { Updated } from "../../../core/internal/util/Updated"
import { UserTargets } from "../../../core/internal/user/UserTarget"
import IdentifierUtil from "../../../core/internal/util/IdentifierUtil"

export class UserManagerImpl implements UserManager {
  private readonly userListeners: UserListener[] = []

  private readonly defaultUser: User = { id: this.hackleDeviceId, deviceId: this.hackleDeviceId }
  private context: UserContext

  constructor(
    private readonly hackleDeviceId: string,
    private readonly storage: UserStorage,
    private readonly userTargetFetcher: UserTargetFetcher,
    private readonly userCohortFetcher: UserCohortFetcher,
    private readonly campaignParser: CampaignParser,
    private readonly pageManager: PageManager,
    private readonly clock: Clock,
    previousUser: User | null,
    initUser: User | null
  ) {
    this.context = this.initContext(previousUser, initUser)
    this.storage.saveUser(this.context.user)
  }

  private initContext(previousUser: User | null, initUser: User | null): UserContext {
    const user = initUser ?? previousUser ?? this.defaultUser
    return UserContext.of(decorate(user, this.hackleDeviceId), UserCohorts.empty(), UserTargets.empty())
  }

  public addListener(listener: UserListener): void {
    this.userListeners.push(listener)
  }

  public get currentUser() {
    return this.context.user
  }

  // HackleUser resolve

  resolve(user?: User | string): HackleUser {
    const context = this.resolveContext(user)
    return this.hackleUser(context)
  }

  private resolveContext(user: User | string | undefined): UserContext {
    if (user === undefined) {
      return this.context
    }

    if (typeof user === "string") {
      return this.updateUser({ id: user }).current
    }

    return this.updateUser(user).current
  }

  toHackleUser(user: User): HackleUser {
    const context = this.context.with(user)
    return this.hackleUser(context)
  }

  private hackleUser(context: UserContext): HackleUser {
    const builder = new IdentifiersBuilder()
      .addIdentifiers(context.user.identifiers || {})
      .add(IdentifierType.ID, context.user.id || this.hackleDeviceId)
      .add(IdentifierType.DEVICE, context.user.deviceId || this.hackleDeviceId)
      .add(IdentifierType.HACKLE_DEVICE, this.hackleDeviceId)

    if (ObjectUtil.isNotNullOrUndefined(context.user.userId)) {
      builder.add(IdentifierType.USER, context.user.userId)
    }
    const identifiers = builder.build()

    const campaign: Properties = this.campaignParser.parse()

    const userProperties: Properties = {
      ...campaign,
      ...context.user.properties
    }

    return {
      identifiers: identifiers,
      properties: userProperties,
      hackleProperties: this.hackleProperties(),
      cohorts: context.cohorts.rawCohorts,
      targetEvents: context.targetEvents.rawEvents
    }
  }

  private hackleProperties(): Properties {
    let hackleProperties
    if (typeof window !== "undefined") {
      hackleProperties = { ...HacklePropertyGenerator.generate(window), ...this.pageManager.currentPage.toProperties() }
    }
    return hackleProperties || {}
  }

  // User update

  public setUser(user: User): Updated<User> {
    return this.updateUser(user).map((it) => it.user)
  }

  public setUserId(userId: string | undefined): Updated<User> {
    const user: User = {
      ...this.context.user,
      userId
    }
    return this.setUser(user)
  }

  public setDeviceId(deviceId: string): Updated<User> {
    const user: User = {
      ...this.context.user,
      deviceId
    }
    return this.setUser(user)
  }

  public updateUserProperties(operations: PropertyOperations): Updated<User> {
    return this.operateProperties(operations).map((it) => it.user)
  }

  public resetUser(): Updated<User> {
    return this.updateContext(() => this.defaultUser).map((it) => it.user)
  }

  private changeUser(oldUser: User, newUser: User, timestamp: number) {
    this.userListeners.forEach((listener) => {
      listener.onUserUpdated(oldUser, newUser, timestamp)
    })
  }

  private saveUser(user: User) {
    this.storage.saveUser(user)
  }

  private updateContext(updater: (user: User) => User): Updated<UserContext> {
    const oldContext = this.context
    const oldUser = oldContext.user
    const newUser = updater(oldUser)

    const newContext = this.context.with(newUser)
    this.context = newContext

    if (!isSameUser(oldUser, newUser)) {
      this.changeUser(oldUser, newUser, this.clock.currentMillis())
      this.saveUser(newUser)
    }

    return new Updated<UserContext>(oldContext, newContext)
  }

  private updateUser(user: User): Updated<UserContext> {
    return this.updateContext((currentUser) => mergeUsers(currentUser, decorate(user, this.hackleDeviceId)))
  }

  private operateProperties(operations: PropertyOperations): Updated<UserContext> {
    return this.updateContext((currentUser) => {
      const userProperties = currentUser.properties ?? {}
      const properties = operations.operate(new Map(Object.entries(userProperties)))

      return { ...currentUser, properties: ObjectUtil.fromMap(properties) }
    })
  }

  // Sync
  async sync(): Promise<void> {
    await Promise.all([this.syncCohort(), this.syncUserTarget()])
  }

  async syncIfNeeded(updated: Updated<User>): Promise<void> {
    if (this.hasNewIdentifiers(updated.previous, updated.current)) {
      await this.syncCohort()
    }

    if (!isSameUser(updated.previous, updated.current)) {
      await this.syncUserTarget()
    }
  }

  private async syncCohort() {
    try {
      const cohorts = await this.userCohortFetcher.fetch(this.currentUser)
      this.context = this.context.update(cohorts, this.context.targetEvents)
    } catch (err) {
      Logger.log.error(`Failed to sync cohorts: ${err}`)
    }
  }

  private async syncUserTarget() {
    try {
      const events = await this.userTargetFetcher.fetch(this.currentUser)
      this.context = this.context.update(this.context.cohorts, events)
    } catch (err) {
      Logger.log.error(`Failed to sync userTargets: ${err}`)
    }
  }

  private hasNewIdentifiers(previous: User, current: User): boolean {
    const previousIdentifiers = resolveIdentifiers(previous)
    const currentIdentifiers = resolveIdentifiers(current)
    return Object.entries(currentIdentifiers).some(([type, value]) => previousIdentifiers[type] !== value)
  }

  async close(): Promise<void> {}
}

export class UserStorage {
  constructor(private readonly storage: IStorage) {}

  public getUser(): User | null {
    const deviceId = this.deviceId || undefined
    const userId = this.userId || undefined

    if (deviceId !== undefined || userId !== undefined) {
      return { deviceId, userId }
    }

    return null
  }

  public saveUser(user: User) {
    this.setDeviceId(user.deviceId || null)
    this.setUserId(user.userId || null)
  }

  public get deviceId(): string | null {
    return this.storage.getItem(DEVICE_ID_STORAGE_KEY)
  }

  public get userId(): string | null {
    return this.storage.getItem(USER_ID_STORAGE_KEY)
  }

  public setDeviceId(deviceId: string | null) {
    this.setId(DEVICE_ID_STORAGE_KEY, deviceId)
  }

  public setUserId(userId: string | null) {
    this.setId(USER_ID_STORAGE_KEY, userId)
  }

  private setId(key: string, value: string | null) {
    if (ObjectUtil.isNotNullOrUndefined(value)) {
      this.storage.setItem(key, value)
    } else {
      this.storage.removeItem(key)
    }
  }
}

class UserContext {
  private constructor(
    readonly user: User,
    readonly cohorts: UserCohorts,
    readonly targetEvents: UserTargets
  ) {}

  static of(user: User, cohort: UserCohorts, targetEvents: UserTargets): UserContext {
    return new UserContext(user, cohort.filterBy(user), targetEvents)
  }

  with(user: User): UserContext {
    return UserContext.of(user, this.cohorts.filterBy(user), this.targetEvents)
  }

  update(cohorts: UserCohorts, targetEvents: UserTargets): UserContext {
    const filtered = cohorts.filterBy(this.user)
    const newCohorts = this.cohorts.toBuilder().putAll(filtered).build()
    return UserContext.of(this.user, newCohorts, targetEvents)
  }
}

const decorate = (user: User, hackleDeviceId: string): User => {
  return {
    ...user,
    id: user.id || hackleDeviceId,
    deviceId: user.deviceId || hackleDeviceId
  }
}
