import { InAppMessagePresentationContext } from "../../presentation/InAppMessagePresenter"
import { InAppMessageUi } from "../InAppMessageUi"
import { InAppMessageView } from "./InAppMessageView"
import { ScriptInAppMessageView } from "./ScriptInAppMessageView"
import { InAppMessageRenderScriptLoader } from "../script/InAppMessageRenderScriptLoader"
import InAppMessageRenderTypeResolver from "../internal/InAppMessageRenderTypeResolver"
import { InAppMessageViewStatus } from "./InAppMessageViewStatus"
import { HackleInAppMessageLifecycleRegistry } from "./InAppMessageLifecycleRegistry"

export class InAppMessageViewFactory {
  constructor(private readonly scriptLoader: InAppMessageRenderScriptLoader) {}

  static create(scriptLoader: InAppMessageRenderScriptLoader): InAppMessageViewFactory {
    return new InAppMessageViewFactory(scriptLoader)
  }

  create(context: InAppMessagePresentationContext, ui: InAppMessageUi): InAppMessageView | null {
    const renderTypeResolver = new InAppMessageRenderTypeResolver()
    const viewStatus = new InAppMessageViewStatus()
    const lifecycleRegistry = new HackleInAppMessageLifecycleRegistry()

    switch (context.message.layout.displayType) {
      case "MODAL":
      case "BANNER":
      case "BOTTOM_SHEET":
        return new ScriptInAppMessageView(
          this.scriptLoader,
          context,
          ui,
          renderTypeResolver,
          viewStatus,
          lifecycleRegistry
        )
      case "NONE":
        return null
    }
  }
}
