export class InAppMessageViewStatus {
  constructor(public current: "OPENED" | "CLOSED" | "OPENING" | "CLOSING" = "CLOSED") {}

  canOpen() {
    return this.current !== "OPENED" && this.current !== "OPENING"
  }

  canClose() {
    return this.current !== "CLOSED" && this.current !== "CLOSING"
  }
}
